<template>
  <Container title="Impersonate">
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="10"
      indeterminate
    />
  </Container>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "Impersonate",
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      loading: true,
    }
  },
  mounted() {
    if (this.$auth.impersonating()) {
      if (this.$auth.user().id == this.id) {
        this.$router.push("/")
      } else {
        this.$auth.unimpersonate({
          makeRequest: false,
          redirect: { name: "Impersonate" },
        })
          .then(() => {
            this.impersonate(this.id)
          })
      }
    } else {
      this.impersonate(this.id)
    }
  },
  methods: {
    impersonate(id) {
      this.$auth.impersonate({
        url: "auth/jwt/impersonate/",
        data: { id },
        redirect: { name: "Dashboard" },
      })
        .then(() => {
          this.loading = false
          this.$router.go("/")
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
})
</script>
